import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { IonInput, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { AllowGpsModalComponent } from '../../../app/components/allow-gps-modal/allow-gps-modal.component';
import { DeliveryNotAvailableModalComponent } from '../../../app/components/delivery-not-available-modal/delivery-not-available-modal.component';
import { DeliveryNotAvailableAction } from '../../../app/enums/DeliveryNotAvailableAction';
import { MenuPage } from '../../../app/pages/menu/menu.page';
import { MapsUtils, calculateGeoDistance } from '../../../maps/utils/maps';
import { MapsUtils as MapUtils2 } from 'src/smoothr-web-app-core/utils/maps-utils';

import { Api } from '../../../smoothr-web-app-core/api/api';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import { coverFlow, sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { MapLocationModalComponent } from 'src/app/components/map-location-modal/map-location-modal.component';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';
import { MapPage } from 'src/app/pages/map/map.page';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-map-venue',
	templateUrl: './map-venue.component.html',
	styleUrls: ['./map-venue.component.scss'],
})
export class MapVenueComponent extends RepositoryDirective implements OnInit, OnDestroy {
	@Input() showSearchInput = false;
	@Input() showVenueList = true;
	@Input() venueToFirstPositionShortId = '';
	@ViewChild('mapContainer', { static: true })
	mapElement: ElementRef;
	@ViewChild('swiper') slides: ElementRef | undefined;
	map: Map;
	@ViewChild(IonInput, { static: true })
	inputField: IonInput;
	@ViewChild('venueList', { static: false }) venueList;
	public searchRestaurant = false;
	public searchRestaurantControl = new FormControl('');
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
		// styles: [

		// ],
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#e9e9e9',
					},
					{
						lightness: 17,
					},
				],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry',
				stylers: [
					{
						color: '#EFEFEF',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.stroke',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 17,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 29,
					},
					{
						weight: 0.2,
					},
				],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 18,
					},
				],
			},
			{
				featureType: 'road.local',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [
					{
						color: '#EFEFEF',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dedede',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [
					{
						visibility: 'on',
					},
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},

			{
				elementType: 'labels.text.fill',
				stylers: [
					{
						saturation: 36,
					},
					{
						color: '#000',
					},
					{
						lightness: 40,
					},
				],
			},
			{
				elementType: 'labels.icon',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [
					{
						color: '#fff',
					},
					{
						lightness: 19,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 17,
					},
					{
						weight: 1.2,
					},
				],
			},
			{
				featureType: 'transit.line',
				stylers: [
					{
						saturation: 100,
					},
					{
						color: '#fefefe',
					},
				],
			},
			{
				featureType: 'transit.station.airport',
				elementType: 'geometry.fill',
				stylers: [
					{
						invert_lightness: false,
						color: '#fefefe',
					},
					{
						hue: '#fefefe',
					},
				],
			},
		],
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: any[] = [];
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2,
		},
		on: coverFlow,
	};
	selectedVenue: Venue;
	MapsUtils = MapsUtils;
	private _showPredictions = false;
	private onDestroy$ = new Subject<void>();
	public allVenuesFiltered: Venue[] = [];
	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address && this.localVenues?.length != 0 ? this.localVenues : this.allVenuesFiltered;
	}

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}

	ngOnInit() {
		super.ngOnInit();

		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		new Promise<void>(async resolve => {
			await this.fillInPlace(null);
			await this.initAutocomplete();
			resolve();
		});
		this.searchRestaurantControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
			if (value) {
				const copyValue = value.toLowerCase();
				this.allVenuesFiltered = this.allVenues.filter(
					venue =>
						venue.name.toLowerCase().includes(copyValue) ||
						venue.readableId.toLowerCase().includes(copyValue) ||
						venue.city.de.toLowerCase().includes(copyValue) ||
						venue.postalCode.includes(copyValue) ||
						venue.street.toLowerCase().includes(copyValue)
				);
				this.resetMap();
			} else {
				this.allVenuesFiltered = this.allVenues;
				this.resetMap();
			}
		});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}

	async resetMap() {
		if (this.allVenues.length === 0) {
			try {
				this.allVenues = (await Api.getAllVenues(environment.customerGroup)).data
					.map(ven => {
						try {
							ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours, ven.specialOpeningHours ?? []);
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours,
							});
						}
						return ven;
					})
					.filter(
						ven =>
							ven.isPublished &&
							(venueAcceptsOrders(ven, PreorderType.DELIVERY) || venueAcceptsOrders(ven, PreorderType.TAKE_AWAY))
					);

				if (environment.sortMapVenue) {
					this.allVenues = this.allVenues.sort((a, b) => {
						const nameA = a?.name?.replace(environment.sortMapVenue, '')?.trim();
						const nameB = b?.name?.replace(environment.sortMapVenue, '')?.trim();
						return nameA?.localeCompare(nameB);
					});
				}
				if (this.address) {
					this.allVenues = this.allVenues.sort((a, b) => {
						return (
							calculateGeoDistance(this.address.lat, this.address.lng, a.location.coordinates[1], a.location.coordinates[0]) -
							calculateGeoDistance(this.address.lat, this.address.lng, b.location.coordinates[1], b.location.coordinates[0])
						);
					});
				}
				this.allVenuesFiltered = this.allVenues;
			} catch (e) {}
		}
		await this.setupMap(null);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue ?? this.relevantVenues[0];
		console.log(this.selectedVenue, selectedVenue);
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(this.clusterer, selectedVenue, this.relevantVenues, this.map, venue =>
			this.setupMap(venue)
		);
		if (selectedVenue && selectedVenue.location && selectedVenue.location.coordinates) {
			const selectedIndex = this.relevantVenues.findIndex(ven => ven._id === selectedVenue._id);
			// wait until slides rendered
			this.scrollToVenue(selectedIndex ?? 0);

			await this.slides.nativeElement.swiper.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	hidePredictions() {
		setTimeout(() => {
			this._showPredictions = false;
		}, 200);
	}

	showPredictions() {
		this._showPredictions = true;
	}

	async executeSearch() {
		console.log('executeSearch()');
		this.loading = true;
		try {
			const result = await MapUtils2.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
	}

	resetSearch() {
		console.log('resetSearch()');
		this.searchTerm = '';
		this.predictions = [];
		this.selectedVenueForDelivery = null;
		this.selectedVenue = null;
		if (this.address) {
			this.repository.address.emit(null);
		}
		this.showHint = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(address: Address) {
		console.log('fillInPlace()', address);
		if (!address) {
			return;
		}
		if (!address?.lat) {
			try {
				const result = await MapUtils2.getPlaceByPred(address as any);
				console.log('RESUlt of mapping', result);
				address = MapUtils2.placeToAddress(result as any);
			} catch (e) {
				this.loading = false;
				return;
			}
		}
		this.loading = true;
		try {
			this.searchTerm = MapsUtils.checkAddress(address);
			this.loading = false;
			if (this.address !== address) {
				this.repository.address.emit(address);
			}
			await this.onSlideChange();
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];

		try {
			this.localVenues = await this.repository.getVenuesByAddress(environment.customerGroup, address);
			this.searchTerm = MapsUtils.addressToString(address);
			if (this.localVenues.length === 0) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm,
					}),
					null,
					{
						duration: 2000,
					}
				);
				this.loading = false;
				await this.resetMap();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven => venueAcceptsOrders(ven, PreorderType.DELIVERY));
				const selectedDeliveryVenue = deliveryVenues.find(ven => ven._id === this.selectedVenueForDelivery._id);
				if (deliveryVenues.length > 0) {
					this.localVenues = deliveryVenues;
					this.setupMap(null);
				}
				// console.log('selected Delivery', selectedDeliveryVenue, deliveryVenues)
				// if (selectedDeliveryVenue) {
				// 	await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				// } else {
				// 	const choice = await DeliveryNotAvailableModalComponent.show(this.modalCtrl);
				// 	switch (choice) {
				// 		case DeliveryNotAvailableAction.DECLINE:
				// 			this.localVenues = deliveryVenues;
				// 			if (deliveryVenues.length === 0) {
				// 				this.repository.address.emit(null);
				// 			} else {
				// 				this.showHint = true;
				// 			}
				// 			await this.setupMap(null);
				// 			break;
				// 		case DeliveryNotAvailableAction.TAKE_AWAY:
				// 			await this.selectVenue(this.selectedVenueForDelivery, PreorderType.TAKE_AWAY);
				// 			break;
				// 	}
				// // }
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides?.nativeElement.swiper.activeIndex;
		console.log(index);
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		console.log('DELIVERY', preorderType);
		if (preorderType === PreorderType.DELIVERY && !this.address) {
			this.snackbarCtrl.open(this.translate.instant('map_page.enter_delivery_address'), null, {
				duration: 2000,
			});
			this.selectedVenueForDelivery = venue;
			this.showHint = true;
			return;
		}
		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
				duration: 2000,
			});
			this.showHint = true;

			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		if (preorderType === PreorderType.PARK_COLLECT) {
			// preorderType = await ParkCollectSheetComponent.show(this.modalCtrl, venue);
		}
		if (preorderType == PreorderType.DELIVERY && this.address && venue) {
			if (!(await this.checkAddressDelivery(venue, this.address))) {
				this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
					duration: 2000,
				});
				this.loading = false;
				this.repository.address.next(null);
				return;
			}
		}
		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, this.address, OrderType.PREORDER, preorderType);
			await sleep(1000);
			this.router.navigateByUrl('/menu', {
				replaceUrl: true,
			});
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}

	async initAutocomplete() {
		console.log('INITAUTO');
		try {
			await MapUtils2.initAutocomplete(
				this.inputField,
				predictions => {
					console.log('PREV', predictions);
					this.predictions = predictions as any[];
					this.cdr.detectChanges();
				}
				// loading => (this.loadingAddress = loading)
			);
		} catch (e) {
			console.log(e);
		}

		this.loading = false;
	}

	async loadPlace(pred: any) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			const result = await MapUtils2.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) {
			await this.fillInPlace(pred);
		}

		this.loading = false;
		this.cdr.detectChanges();

		// if (this.loading) {
		// 	return;
		// }
		// this.loading = true;
		// await this.fillInPlace(pred);
		// this.loading = false;
		// this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		new Promise(async () => {
			if (this.address) {
				await this.loadVenues(this.address);
			} else {
				await this.resetMap();
				// this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
	mapPrediction(value: any) {
		console.log(value);
		return 'test';
	}
	async checkAddressDelivery(venue: Venue, address: Address) {
		if (
			// OrderUtils.isDelivery(this.order) &&
			this.address.number &&
			this.address.city &&
			this.address.postalCode &&
			this.address.street
		) {
			try {
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					console.log('not valid adrress', addressValidationResult);
					// await InfoModalComponent.show(this.modalCtrl, {
					// 	info: this.translate.instant(
					// 		'map_page.venue_does_not_delivery_to_address'
					// 	),
					// 	closeButton: this.translate.instant('add_points.close')
					// });
					return false;
				}
				if (venue.deliveryByAreas && venue?.deliveryAreas?.length > 0) {
					return true;
				}
				console.log(address.lat, address.lng, venue.location.coordinates[1], venue.location.coordinates[0]);
				const delivers =
					(venue.deliveryByRadius &&
						calculateGeoDistance(address.lat, address.lng, venue.location.coordinates[1], venue.location.coordinates[0]) <=
							venue.deliveryRadius) ||
					(venue.isPostalDelivery && venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					// this.orderForm.controls['street'].setErrors({street_delivery: true});

					this.snackbarCtrl.open(this.translate.instant('errors.street_delivery'), null, {
						duration: 2000,
					});
					return false;
				}
				// this.repository.address.next(address);
				// this.orderForm.patchValue({
				// 	street: this.address.street,
				// 	number: this.address.number,
				// 	postalCode: this.address.postalCode,
				// 	city: this.address.city
				// });
				return true;
			} catch (e) {
				console.log(e);
				// this.orderForm.controls['street'].setErrors({street_not_found: true});
				// this.snackbarCtrl.open(
				// 	this.translate.instant('errors.street_not_found'),
				// 	null,
				// 	{
				// 		duration: 2000
				// 	}
				// );
				return false;
			}
		} else {
			return false;
		}
	}
	private async scrollToVenue(index: number) {
		try {
			await this.venueList.scrollToPoint(0, this.venueList.el.children[index].offsetTop - 250, 1000);
		} catch (e) {}
	}
}
