// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.wrapper .cross-icon {
  position: absolute;
  z-index: 99;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
.wrapper ion-img {
  max-height: 70vh;
  height: 70vh;
}
@media (max-width: 992px) {
  .wrapper ion-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/banner-info/banner-info.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AADF;AAEE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAEE;EACE,gBAAA;EACA,YAAA;AAAJ;ACTE;EDOA;IAII,YAAA;IACA,WAAA;IACA,mBAAA;EAEJ;AACF","sourcesContent":["@import '../../../theme/mixins';\n\n.wrapper {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  .cross-icon {\n    position: absolute;\n    z-index: 99;\n    top: 10px;\n    right: 10px;\n    width: 20px;\n    height: 20px;\n  }\n  ion-img {\n    max-height: 70vh;\n    height: 70vh;\n    @include mobile {\n      height: 100%;\n      width: 100%;\n      object-fit: contain;\n    }\n  }\n}","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
