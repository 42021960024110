import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
export enum TOGGLE_TAG {
	All = 'all',
	Vegeterian = 'veggie',
	Vegan = 'vegan',
	Fish = 'fish',
	Meat = 'meat',
	Alcohol = 'alcohol',
}

@Component({
	selector: 'app-menu-filters',
	standalone: true,
	imports: [CommonModule, IonicModule],
	template: `
		<div class="vegan_toggle">
			<!-- <div class="filter-toggle" (click)="showFiltersFunc()">
				<ion-icon src="../../../assets/toggle/filter.svg"></ion-icon>
				<p>Filter</p>
			</div> -->
			<div class="toggler" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.All)"
					[class.selected]="selectedToggleTag === toggleTagEnum.All"
				>
					All
				</div>
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.Meat)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Meat"
				>
					<ion-icon src="../../../assets/losteria/slider/meat.svg"></ion-icon>
				</div>
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.Fish)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Fish"
				>
					<ion-icon src="../../../assets/losteria/slider/fish.svg"></ion-icon>
				</div>
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.Vegeterian)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Vegeterian"
				>
					<ion-icon src="../../../assets/losteria/slider/vegetarian.svg"></ion-icon>
				</div>
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.Vegan)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Vegan"
				>
					<ion-icon src="../../../assets/losteria/slider/vegan.svg"></ion-icon>
				</div>
				<div
					class="toggler_item"
					(click)="toggleSwitch(toggleTagEnum.Alcohol)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Alcohol"
				>
					<ion-icon src="../../../assets/losteria/slider/non-alcohol.svg"></ion-icon>
				</div>
				<div
					class="toggler_pin"
					[ngClass]="{
						first: selectedToggleTag === toggleTagEnum.All,
						second: selectedToggleTag === toggleTagEnum.Meat,
						third: selectedToggleTag === toggleTagEnum.Fish,
						fourth: selectedToggleTag === toggleTagEnum.Vegeterian,
						fifth: selectedToggleTag === toggleTagEnum.Vegan,
						sixth: selectedToggleTag === toggleTagEnum.Alcohol
					}"
				></div>
			</div>
		</div>
	`,
	styleUrls: ['menu-filters.component.scss'],
})
export class MenuFiltersComponent {
	swipeCoord = [0, 0];
	swipeTime = new Date().getTime();
	@Input() selectedToggleTag: TOGGLE_TAG;
	@Input() showFilters = false;
	@Output() showFiltersValue = new EventEmitter<boolean>();
	@Output() toggleTagValue = new EventEmitter<TOGGLE_TAG>();

	toggleTagEnum = TOGGLE_TAG;

	showFiltersFunc() {
		this.showFilters = !this.showFilters;
		this.showFiltersValue.emit(this.showFilters);
	}
	toggleSwitch(selectedItem: TOGGLE_TAG) {
		this.selectedToggleTag = selectedItem;
		this.toggleTagValue.emit(selectedItem);
	}
	swipe(e: TouchEvent, when: string) {
		const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
		const time = new Date().getTime();

		if (when === 'start') {
			this.swipeCoord = coord;
			this.swipeTime = time;
		} else if (when === 'end') {
			const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
			const duration = time - this.swipeTime;
			if (
				duration < 1000 && //
				Math.abs(direction[0]) > 30 && // Long enough
				Math.abs(direction[0]) > Math.abs(direction[1] * 3)
			) {
				const swipeDir = direction[0] > 0 ? 'next' : 'previous';
				if (swipeDir === 'next') {
					this.toggleSwitch(this.toggleTagEnum.Vegeterian);
				} else {
					this.toggleSwitch(this.toggleTagEnum.All);
				}
			}
		}
	}
}
