// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-button {
  --background: transparent;
  color: var(--ion-color-primary);
  --border-color: var(--ion-color-primary);
  --border-width: 2px;
  --border-style: solid;
}

ion-item.input-error {
  margin-bottom: 8px !important;
}

ion-item {
  --border-width: 1px !important;
  border: none;
}

.forgot-password {
  margin: 20px 0;
  cursor: pointer;
}
.forgot-password h3 {
  color: var(--ion-color-primary) !important;
  font-size: 14px;
  font-weight: bold;
}

.wrapper {
  padding: 25px;
}

.stacked {
  position: static;
  top: 0px;
  left: 0px;
  z-index: 2;
}

ion-note {
  font-size: 12px;
  padding-right: 10px;
}

ion-item {
  --border-radius: 5px;
  margin-bottom: 0;
}

.hello-again {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sign-in/sign-in.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,+BAAA;EACA,wCAAA;EACA,mBAAA;EACA,qBAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AACA;EACE,8BAAA;EACA,YAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;AAAE;EACE,0CAAA;EACA,eAAA;EACA,iBAAA;AAEJ;;AACA;EACE,aAAA;AAEF;;AACA;EACC,gBAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AAED;;AACA;EACE,eAAA;EACA,mBAAA;AAEF;;AACA;EACE,oBAAA;EACA,gBAAA;AAEF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".sign-up-button {\n  --background: transparent;\n  color: var(--ion-color-primary);\n  --border-color: var(--ion-color-primary);\n  --border-width: 2px;\n  --border-style: solid;\n}\n\nion-item.input-error {\n  margin-bottom: 8px !important;\n}\nion-item {\n  --border-width: 1px !important;\n  border: none;\n}\n\n.forgot-password {\n  margin: 20px 0;\n  cursor: pointer;\n\n  h3 {\n    color: var(--ion-color-primary) !important;\n    font-size: 14px;\n    font-weight: bold;\n  }\n}\n.wrapper{\n  padding: 25px;\n}\n\n.stacked {\n\tposition: static;\n\ttop: 0px;\n\tleft: 0px;\n\tz-index: 2;\n}\n\nion-note {\n  font-size: 12px;\n  padding-right: 10px;\n}\n\nion-item {\n  --border-radius: 5px;\n  margin-bottom: 0;\n}\n.hello-again {\n  margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
