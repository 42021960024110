import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, type OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import { AllergensInfoModalComponent } from '../../allergens-info-modal/allergens-info-modal.component';
import { getBasePrice, getPrice, numberD, numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import { DisplayIdentifier } from 'src/smoothr-web-app-core/enums/DisplayIdentifier';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';

@Component({
	selector: 'app-article-option-group-checkbox-item',
	templateUrl: './article-option-group-checkbox-item.component.html',
	styleUrls: ['./article-option-group-checkbox-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleOptionGroupCheckboxItemComponent implements OnInit {
	OrderType = OrderType;
	di = DisplayIdentifier;
	getPrice = getPrice;
	numberToCurrency = numberToCurrency;
	basePrice = '';

	constructor(private modalCtrl: ModalController) {}
	ngOnInit(): void {
		this.showMeasurement(this.option);
	}

	@Input() option: Article;
	@Input() isDisabled: Boolean;
	@Input() preorderType: PreorderType;
	@Input() article: Article;
	@Input() currency: string;
	@Input() selected: ArticleOption[];
	@Input() optionGroup: OptionGroup = null;

	@Output() toggleEmitter = new EventEmitter<Article>();

	toggle(option: Article) {
		this.toggleEmitter.emit(option);
	}

	isOptionSelected(option: Article): boolean {
		return this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup?._id) !== undefined;
	}

	async openInfoClick(option: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, option);
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article.nutritions)
			.map(it => (it ? numberD(it) : -1))
			.filter(it => typeof it === 'number')
			.some(it => +it >= 0);
	}

	showMeasurement(article: Article) {
		// console.log('!!!!!!!!', article, article?.measurement?.refAmount, article?.measurement?.refUnit, article?.measurement?.amount);

		if (article && this.preorderType && (article.basePriceByType || (article.basePrice && +article.basePrice.$numberDecimal > 0))) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit && article.measurement.amount) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					this.basePrice =
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency);
					return;
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					this.basePrice =
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency);
					return;
				}
				this.basePrice =
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency);
				return;
			}
		} else {
			return (this.basePrice = '');
		}
	}
}
