import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AllowGpsModalResult } from '../allow-gps-modal/allow-gps-modal.component';

@Component({
	selector: 'app-map-location-modal',
	standalone: true,
	imports: [CommonModule, IonicModule, TranslateModule],
	templateUrl: './map-location-modal.component.html',
	styleUrls: ['map-location-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLocationModalComponent {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			cssClass: 'map-location-modal auto-height',
			component: MapLocationModalComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();

		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	onPositiveClick() {
		this.modalCtrl.dismiss(AllowGpsModalResult.ALLOW);
	}

	onNegativeClick() {
		this.modalCtrl.dismiss(AllowGpsModalResult.DISALLOW);
	}
}
