import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Api, PAYMENT_SERVICE } from '../../api/api';
import { ModalController } from '@ionic/angular';
import Payment from '../../models/Payment';
import { environment } from '../../../environments/environment';
import { PaymentMethod } from '../../enums/PaymentMethod';
import Venue from '../../models/Venue';
import Order from '../../models/Order';
import { PaymentStatus } from '../../models/PaymentStatus';
import Customer from '../../models/Customer';
import { OrderUtils } from '../../utils/order-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { paymentFromOrders, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { AdyenCheckout, ApplePay, GooglePay } from '@adyen/adyen-web';
import { CustomCard } from '@adyen/adyen-web';
import { NavigationService } from 'src/app/services/navigation.service';
export enum PaymentModalResult {
	SUCCESS,
	ERROR,
	DISMISS,
}

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;
// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-modal',
	templateUrl: './payment-modal.component.html',
	styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
	payment: Payment;
	venue: Venue;
	orders: Order[];
	customer: Customer;
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	orderUtils = OrderUtils;
	environment = environment;
	payments = {};

	isAdyenLoading = false;
	wpwlOptionsBase = {
		locale: 'de',
		style: 'plain',
	};
	forceAddressInput: false;

	constructor(
		private modalCtrl: ModalController,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private navService: NavigationService
	) { }

	get order(): Order {
		return this.orders[0];
	}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		orders: Order[],
		customer: Customer,
		tip: number = 0,
		forceAddressInput: boolean = false
	): Promise<{ result: PaymentModalResult; error: any; payment: Payment }> {
		const paymentMethods: PaymentMethod[] = [];
		if (venue.ccEnabled) {
			paymentMethods.push(PaymentMethod.CREDIT_CARD);
		}
		if (venue.gpEnabled) {
			paymentMethods.push(PaymentMethod.GPAY);
		}
		if (venue.apEnabled && applePayAvailable) {
			paymentMethods.push(PaymentMethod.APAY);
		}
		if (venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		if (venue.sbEnabled) {
			paymentMethods.push(PaymentMethod.SOFORTBANKING);
		}

		//TURN OF FOR TABILO
		if (venue.cashEnabled && venue.cashPreorderEnabled) {
			paymentMethods.push(PaymentMethod.CASH);
		}
		if (venue.ccvEnabled) {
			paymentMethods.push(PaymentMethod.CCV);
		}
		if (venue.adyenEnabled) {
			paymentMethods.push(PaymentMethod.ADYEN);
		}
		if (venue.adyenEnabled && venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		const modal = await modalCtrl.create({
			component: PaymentModalComponent,
			componentProps: {
				payment: paymentFromOrders(orders, tip),
				paymentMethods,
				venue,
				orders,
				customer,
				forceAddressInput,
			},
			backdropDismiss: false,
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	ngOnInit() {
		this.analytics.paymentModalOpened(this.order);
	}

	async loadPayment() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.resetJs();
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;
		this.selectedPaymentMethod = null;
		wpwlOptions = null;
		this.cdr.detectChanges();
		this.selectedPaymentMethod = this.payment.method;

		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					if (!this.venue.adyenEnabled) {
						await this.loadPaypal();
					} else {
						await this.adyenLoadPayPal();
					}

					break;
				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					await this.loadGpay();
					break;
				case PaymentMethod.APAY:
					await this.loadApay();
					break;
				case PaymentMethod.CASH:
					await this.loadCashButton();
					break;
				case PaymentMethod.CCV:
					await this.ccvPay(PaymentMethod.CCV);
					break;
				case PaymentMethod.ADYEN:
					await this.paymentMethodAdyen();
					break;
			}
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(this.translate.instant('payment_modal.error'));
			this.orders.forEach(order => {
				this.analytics.paymentLoadingFailed(order, this.selectedPaymentMethod);
			});
			this.selectedPaymentMethod = null;
			this.loading = false;
			return false;
		}
		this.loading = false;
		this.orders.forEach(order => {
			this.analytics.paymentLoaded(order, this.selectedPaymentMethod);
		});
	}
	async adyenLoadPayPal() {
		try {
			this.payment.method = PaymentMethod.PAYPAL;
			this.payment = await Api.createPayment(this.payment);
			if (this.payment?.adyenPayment?.redirectUrl) {
				window.location.assign(this.payment?.adyenPayment?.redirectUrl);
			}
		} catch (e) { }
	}
	async paymentMethodAdyen() {
		// await sleep(1000);
		const configuration = {
			locale: 'de_DE',
			environment: 'test',
			clientKey: this.venue.adyenCredentials.clientKey,
			countryCode: 'DE',
			currency: 'EUR',
			analytics: {
				enabled: false, //disable analytics cors errors
			},
			hasHolderName: true,
			holderNameRequired: true,
			billingAddressRequired: true,
		} as any;
		const checkout = await AdyenCheckout(configuration);
		console.log(checkout);
		console.log(new CustomCard(checkout));
		const customCard = new CustomCard(checkout, {
			type: 'card',
			brands: ['mc', 'visa', 'amex', 'bcmc', 'maestro'],
			styles: {
				error: { color: 'red' },
				validated: { color: 'green' },
				placeholder: { color: '#d8d8d8' },
			},
			onLoad: function (value) {
				console.log('Custom Card Loaded', value);
			},
			onError: function (err) {
				console.log('Error:', err);
			},
		} as any).mount('#customCard-container');

		document.getElementById('payButton').addEventListener('click', () => {
			if (customCard.state.data && customCard.state.isValid) {
				const stateData = customCard.state.data;
				const object = {
					card: {
						number: stateData.encryptedCardNumber,
						month: stateData.encryptedExpiryMonth,
						year: stateData.encryptedExpiryYear,
						code: stateData.encryptedSecurityCode,
					},
				};
				this.startPaymentAdyen(object);
			}
		});
	}
	async startPaymentAdyen(data: {
		card: {
			number: any;
			month: any;
			year: any;
			code: any;
		};
	}) {
		try {
			this.isAdyenLoading = true;
			this.payment = {
				...this.payment,
				method: PaymentMethod.CREDIT_CARD,
				adyenPayment: data,
			};
			this.payment = await Api.createPayment(this.payment);
			if (this.payment.status === PaymentStatus.created) {
				await sleep(4000);
				const paymentData = (await Api.getPayment(this.payment._id)).data;
				console.log(paymentData);
				if (paymentData.status === PaymentStatus.done) {
					this.modalCtrl.dismiss();
					await sleep(500);
					await this.navService.paymentSuccess(paymentData.orders[0], paymentData._id);
				}
				if (this.payment?.adyenPayment?.redirectUrl) {
					const form = document.createElement('form');
					form.method = 'POST';
					form.action = this.payment.adyenPayment.redirectUrl;
					const paReqInput = document.createElement('input');
					paReqInput.type = 'hidden';
					paReqInput.name = 'PaReq';
					paReqInput.value = this.payment.adyenPayment.cardAuthRequestPaReq;
					form.appendChild(paReqInput);
					const mdInput = document.createElement('input');
					mdInput.type = 'hidden';
					mdInput.name = 'MD';
					mdInput.value = this.payment.adyenPayment.cardPaymentSessionMd;
					form.appendChild(mdInput);
					document.body.appendChild(form);
					form.submit();
				}
			}
		} catch (e) {
			this.isAdyenLoading = false;
			console.log(e);
		}
	}
	async ccvPay(paymentMethod: PaymentMethod) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				this.payment = await Api.createCCVPayment(this.venue._id, this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));
				this.loading = false;
				resolve();
			} catch (e) {
				console.error(e, 'error while button setup');
				this.loading = false;
				reject();
			}
		});
	}
	loadVr(paymentMethod: PaymentMethod, paramsBuilder: (payment: Payment) => any): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					console.log({ paymentMethod, wpwl: prevPayment.wpwlOptions });
					this.payment = prevPayment;
					wpwlOptions = JSON.parse(JSON.stringify(this.payment.wpwlOptions));
					console.log(wpwlOptions);
					await this.loadVrJs(this.payment.vrPayment.checkoutId);
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				wpwlOptions = {
					...this.wpwlOptionsBase,
					...paramsBuilder(this.payment),
				};
				console.log({ paymentMethod, wpwl: wpwlOptions });
				this.payment.wpwlOptions = JSON.parse(JSON.stringify(wpwlOptions));
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadVrJs(this.payment.vrPayment.checkoutId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async loadCreditCard() {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			await this.loadPaypalCreditCard();
		}
		await this.loadVr(PaymentMethod.CREDIT_CARD, payment => ({
			billingAddress: this.getBillingAddress(this.order, this.customer),
			total: payment.sum,
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true,
			},
		}));
	}

	async loadGpay() {
		await this.loadVr(PaymentMethod.GPAY, payment => ({
			googlePay: {
				gatewayMerchantId: payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true,
			},
		}));
	}

	async loadApay() {
		await this.loadVr(PaymentMethod.APAY, payment => ({
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				createCheckout: () => {
					return this.payment.vrPayment.checkoutId;
				},
				onCancel: () => {
					console.log('onCancel');
					this.loading = false;
				},
				onPaymentAuthorized: applePayment => {
					console.log('onPaymentAuthorized payment: ' + JSON.stringify(applePayment));
					// this.payment.status = PaymentStatus.done;
					// this.orders.forEach(order => {
					// 	this.analytics.purchase(order, this.payment);
					// });
					// this.modalCtrl.dismiss({
					// 	result: PaymentModalResult.SUCCESS,
					// 	payment: this.payment,
					// });
				},
			},
		}));
	}

	getBillingAddress(order: Order, customer: Customer): { country: string; city: string; postcode: string; street1: string } {
		if (customer && customer.street) {
			return {
				country: customer.country.toUpperCase(),
				city: customer.city,
				postcode: customer.postalCode,
				street1: customer.street + ' ' + customer.number,
			};
		}
		if (this.forceAddressInput) {
			return {
				country: '',
				city: '',
				postcode: '',
				street1: '',
			};
		}
		return {
			country: order.preorder?.country?.toUpperCase(),
			city: order.preorder?.city,
			postcode: order.preorder?.postalCode,
			street1: order.preorder?.street + ' ' + order.preorder?.number,
		};
	}

	resetJs() {
		const head = document.getElementsByTagName('head')[0];
		const prevVrJs = document.getElementById('vr-js');
		if (prevVrJs) {
			head.removeChild(prevVrJs);
		}
		const prevPpJs = document.getElementById('paypal-js');
		if (prevPpJs) {
			head.removeChild(prevPpJs);
		}
	}

	loadVrJs(checkoutId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('paymentWidgets')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'vr-js';
			script.src = `${environment.VR_URL}?checkoutId=${checkoutId}`;
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
				this.loading = false;
			};
			script.onload = async () => {
				this.loading = false;

				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.PAYPAL, 'paypal-button');
	}

	loadKlarna(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.SOFORTBANKING, 'klarna-button');
	}

	loadPaypalCreditCard(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.CREDIT_CARD, 'paypal-cc-button');
	}

	loadPaypalJs(payment: Payment): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'paypal-js';
			script.src = environment.PAYPAL.JS_URL + payment.currency + '&merchant-id=' + payment.merchantId;
			// script.src =
			// 	'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=';
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => reject('Could not load script');
			script.onload = () => resolve();
		});
	}

	loadPaypalButton(payment: Payment, fundingSource: any, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// @ts-ignore
			const button = paypal.Buttons({
				intent: 'capture',
				commit: true,
				vault: false,
				enableStandardCardFields: true,
				createOrder: (data, actions) => {
					return payment.paypal.orderId;
				},
				onCancel: close => {
					console.log('CANCEL');
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: {
							error: PaymentMethod.PAYPAL,
							orderId: this.order._id,
						},
					});
					return;
				},

				onApprove: data => {
					this.finishPaypal(payment, data)
						.then(() => {
							console.log('PAYMENT DONE');
							resolve();
						})
						.catch(err => {
							console.log('ERROR DISMISS');
							reject(err);
						});
				},

				onError: err => {
					this.loading = true;
					console.log('ERRRROR IN PAYPAL PAYMENT');
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: err,
					});
				},
				fundingSource,
			});
			if (!button.isEligible()) {
				reject('Button not eligible');
				return;
			}
			button.render('#' + elementId);
			resolve();
		});
	}

	async finishPaypal(payment: Payment, data: any) {
		this.loading = true;
		try {
			const result = await Api.finishPaypalPayment(data.orderID);
			console.log('RESULT finish payment');
			// this.orders.forEach(order => {
			// 	this.analytics.purchase(order, result);
			// });
			await this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				payment: result,
			});
		} catch (e) {
			console.log('ERROR IN FINISHED PAYMENT');
			this.orders.forEach(order => {
				this.analytics.paymentFailed(order, payment, 'error finishing paypal ' + e.toString());
			});
			this.loading = false;
			throw e;
		}
	}

	loadPaypalBase(paymentMethod: PaymentMethod, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					this.payment = prevPayment;
					await this.loadPaypalJs(this.payment);
					// @ts-ignore
					await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
					resolve();
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadPaypalJs(this.payment);
				// @ts-ignore
				await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
				resolve();
			} catch (e) {
				console.error('error while paypal setup');
				reject();
			}
		});
	}

	paymentMethodToPaypalFunding(paymentMethod: PaymentMethod): any {
		switch (paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.SOFORTBANKING:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.PAYPAL:
				// @ts-ignore
				return paypal.FUNDING.PAYPAL;
			default:
				return null;
		}
	}

	async dismiss() {
		if (this.loading) {
			return;
		}
		if (this.orders[0]?.sanifairVouchers?.length > 0) {
			// try {
			// 	await Api.extendSanifairVoucher(this.orders[0]?._id);
			// } catch (e) {
			// 	this.snackBarCtrl.open('Smth go wrong while extend order...', null, {
			// 		duration: 4000
			// 	});
			// }
		}
		this.modalCtrl.dismiss({
			result: PaymentModalResult.DISMISS,
		});
	}
	async loadCashButton() {
		this.selectedPaymentMethod = PaymentMethod.CASH;
	}
	async loadCashPay() {
		console.log('Cash Payment');
		this.loading = true;
		this.payment = await Api.createPayment(this.payment);
		this.loading = false;
		this.modalCtrl.dismiss({
			result: PaymentModalResult.SUCCESS,
			payment: this.payment,
		});
	}
	navigateCvv(url: string) {
		window.location.replace(url);
	}
}
