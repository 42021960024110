import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { MenuPage } from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { NavigationService } from 'src/app/services/navigation.service';
import { MapPage } from '../map/map.page';
import { MapLocationModalComponent } from 'src/app/components/map-location-modal/map-location-modal.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { SelectLanguagePopoverComponent } from 'src/app/components/select-language-popover/select-language-popover.component';
import { AllowGpsModalResult } from 'src/app/components/allow-gps-modal/allow-gps-modal.component';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.losteria.html',
	styleUrls: ['./home.page.losteria.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static urlHome = 'account';

	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	opened = false;
	selectedLanguage = Language.German;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private popover: PopoverController
	) {
		super(repository);
	}

	// static async navigate(router: Router, repository?: RepositoryService) {
	// 	if (repository) {
	// 		repository.resetVenue();
	// 		repository.address.emit(null);
	// 		repository.order.emit(null);
	// 		repository.verifiedOrder.emit(null);
	// 		repository.payment.emit(null);
	// 	}

	// 	await router.navigateByUrl(HomePage.url);
	// }

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		// this.loadVenues();
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.onOrderChange(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores() {
		// this.loading = true;
		const result = await MapLocationModalComponent.show(this.modalCtrl);
		console.log(result);
		if (result === AllowGpsModalResult.ALLOW) {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
			await sleep(200);
		} else {
			this.repository.address.next(null);
		}
		await MapPage.navigate(this.router);

		// const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		// if (!venue) {
		// 	console.log('No available venues');
		// 	this.loading = false;
		// 	return;
		// }
		// await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, OrderType.PREORDER, preorderType);
		await this.navigationService.menu();
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues(environment.customerGroup);
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	async openPopoverLanuage(ev: any) {
		console.log(ev);

		this.opened = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			cssClass: 'popover-home',
			event: ev,
			translucent: true,
			showBackdrop: false,
			side: 'top',
			alignment: 'end',
			reference: 'event',
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			console.log(v);
			if (v.data) {
				this.translate.setDefaultLang(v.data);
				this.translate.use(v.data);
				this.selectedLanguage = v.data;
			}

			this.opened = false;
		});
		await popover.present();

		return;
	}

	selectedImage() {
		return '../../../assets/' + this.selectedLanguage + '.svg';
	}
	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
}
