import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';

import LatLngBounds = google.maps.LatLngBounds;
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import LatLng = google.maps.LatLng;
import MarkerClusterer from '@googlemaps/markerclustererplus';

import { resolve } from 'url';
import Address from 'src/smoothr-web-app-core/models/Address';
import { getLatLng, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';
import { NominatimPlace } from 'src/smoothr-web-app-core/models/NominatimPlace';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Order from 'src/smoothr-web-app-core/models/Order';

export const selectedMark = '/assets/icon/maps_marker_selected.svg';
export const notSelectedMark = '/assets/icon/maps_marker.svg';

export class MapsUtils {
	static initAutocomplete(
		ionInput: IonInput,
		callback: (predictions: Address[]) => void,
		loadingCallback: (loading: boolean) => void
	): Subscription {
		let prevRequest: Promise<Address[]> = null;
		return ionInput.ionChange.subscribe(async event => {
			if (prevRequest) {
				await prevRequest;
				await sleep(100);
			}
			loadingCallback(true);
			const value = event.detail.value;
			if (value.replace(' ', '').length === 0) {
				callback([]);
				loadingCallback(false);
				return;
			}
			prevRequest = new Promise<Address[]>(async (resolve, reject) => {
				try {
					resolve(await this.getNominatimPrediction(value));
				} catch (e) {
					reject(e);
				}
			});
			callback(await prevRequest);
			loadingCallback(false);
			prevRequest = null;
		});
	}

	static async getNominatimPrediction(input: string): Promise<Address[]> {
		const data = (await Api.mapSearch(input, 5)).data;
		console.log(
			data.map(nplace => ({
				title: nplace.display_name,
				importance: nplace.importance,
				category: nplace.category,
			}))
		);
		const addresses = data
			// .filter(
			// 	nplace =>
			// 		// nplace.category === 'place' ||
			// 		// nplace.category === 'building' ||
			// 		// nplace.category === 'highway' ||
			// 		// nplace.category === 'man_made' ||
			// 		// nplace.category === 'boundary' ||
			// 		// nplace.category === 'shop' ||
			// 		// nplace.category === 'amenity'
			// )
			.map(nplace => this.placeToAddress(nplace))
			.filter(address => !ValidationUtils.validateAddress(address, false, false));
		const titles = addresses.map(address => this.addressToString(address));
		return addresses.filter((address, addressIndex) => {
			return titles.indexOf(titles[addressIndex]) === addressIndex;
		});
	}

	// 	static async getGooglePrediction(input: string): Promise<GoogleAutoCompletePrediction[]> {
	// 		return (await Api.googleAutoComplete(input)).data.predictions as GoogleAutoCompletePrediction[];
	// 	}

	static checkAddress(address: Address): string {
		const addressError = ValidationUtils.validateAddress(address, false, false);
		if (addressError) {
			console.error('Maps error: ' + addressError);
			throw addressError;
		}
		return this.addressToString(address);
	}

	static async executeSearch(ionInput: IonInput): Promise<Address> {
		return new Promise<Address>(async (resolve, reject) => {
			const input = await ionInput.getInputElement();
			const inputValue = input.value;
			if (!inputValue || inputValue.length <= 0) {
				reject();
				return;
			}
			try {
				const result = MapsUtils.getPlace(inputValue);
				if (result) {
					resolve(result);
				} else {
					reject('No results');
				}
				return;
			} catch (e) {
				reject(e);
			}
		});
	}

	static async getPlace(pred: string): Promise<Address> {
		return new Promise<Address>(async (resolve, reject) => {
			try {
				// 				try {
				const predictions = await this.getNominatimPrediction(pred);
				if (predictions.length < 1) {
					resolve(null);
					return;
				}
				resolve(predictions[0]);
				// 				} catch (e) {
				// 					const predictions = await this.getGooglePrediction(pred);
				// 					if (predictions.length < 1) {
				// 						resolve(null);
				// 					}
				// 					const place = await this.getGooglePlace(predictions[0].place_id);
				// 					resolve(place);
				// 				}
			} catch (e) {
				reject(e);
			}
		});
	}

	// 	static async getGooglePlace(placeId: string): Promise<Address> {
	// 		const googlePlaceDetails = (await Api.googlePlaceDetails(placeId)).data.result;
	// 		return this.placeToAddress(googlePlaceDetails) as Address;
	// 	}

	static placeToAddress(place: NominatimPlace): Address {
		if (!place) {
			return null;
		}
		// 		if ((place as any).address_components) {
		// 			return this.googlePlaceToAddress(place as GooglePlaceDetailsResult);
		// 		}
		// 		place = place as NominatimPlace;
		const address = new Address();
		address.number = place.address.house_number || place.address.house_name || null;
		address.street = place.address.road || place.address.construction || null;
		address.city = place.address.city || place.address.town || place.address.village || place.address.municipality || null;
		address.state =
			place.address.state ||
			place.address.region ||
			place.address.state_district ||
			place.address.county ||
			place.address.city ||
			null;
		address.country = place.address.country || place.address.country_code || null;
		address.postalCode = place.address.postcode || null;
		address.lat = +place.lat || null;
		address.lng = +place.lon || null;
		address.placeId = place.place_id;
		address.displayName = place.display_name;
		return address;
	}

	static addressToString(address: Address): string {
		if (!address) {
			return '';
		}
		if (address.displayName?.length > 0) {
			return address.displayName;
		}
		let title = '';
		if (address.street) {
			title += address.street;
		}
		if (address.number) {
			title += ' ' + address.number;
		}
		if (title !== '' && (address.postalCode || address.city)) {
			title += ',';
		}
		if (address.postalCode) {
			if (title !== '') {
				title += ' ';
			}
			title += address.postalCode;
		}
		if (address.city) {
			if (title !== '') {
				title += ' ';
			}
			title += address.city;
		}

		return title;
	}

	static async getUserGeocode(): Promise<Address> {
		const geocoderPromise = new Promise<Address>((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async position => {
					try {
						const result = (await Api.geocode(position.coords.latitude, position.coords.longitude)).data;
						if (result) {
							resolve(MapsUtils.placeToAddress(result));
						} else {
							reject('No result');
						}
					} catch (e) {
						reject(e);
					}
				},
				error => reject(error)
			);
		});
		const timeoutPromise = new Promise<Address>((resolve, reject) => {
			setTimeout(() => reject('Timeout'), 10000);
		});
		return Promise.race([geocoderPromise, timeoutPromise]);
	}

	static addVenuesToMap(
		clusterer: MarkerClusterer,
		selectedVenue: Venue,
		venues: Venue[],
		map: Map,
		onClick: (venue: Venue) => void
	): MarkerClusterer {
		clusterer =
			clusterer ||
			new MarkerClusterer(map, [], {
				gridSize: 30,
			});
		const newVenues = venues.filter(
			ven =>
				clusterer
					.getMarkers()
					.find(
						mark =>
							ven.location?.coordinates[1] === mark.getPosition().lat() &&
							ven.location?.coordinates[0] === mark.getPosition().lng()
					) === undefined
		);
		const marksToRemove = clusterer
			.getMarkers()
			.map((mark, index) =>
				venues.find(
					ven =>
						ven.location?.coordinates[1] === mark.getPosition().lat() &&
						ven.location?.coordinates[0] === mark.getPosition().lng()
				) === undefined
					? index
					: -1
			)
			.filter(i => i >= 0);
		for (const markToRemove of marksToRemove.reverse()) {
			clusterer.removeMarker(clusterer.getMarkers()[markToRemove]);
		}
		let venueIndex = 0;
		const bounds = new LatLngBounds();
		clusterer.getMarkers().forEach(mark => mark.setIcon(notSelectedMark));
		for (const venue of venues) {
			const latLng = getLatLng(venue);
			if (!latLng) {
				continue;
			}
			const position = new LatLng(latLng.latitude, latLng.longitude);
			if (latLng.latitude !== 0 && latLng.longitude !== 0) {
				bounds.extend(position);
			}
			if (newVenues.find(ven => ven._id === venue._id)) {
				const marker = new Marker({
					position,
					icon: notSelectedMark,
					animation: google.maps.Animation.DROP,
					clickable: true,
				});
				marker.addListener('click', () => {
					onClick(venue);
				});
				clusterer.addMarker(marker, true);
				venueIndex++;
			}
		}
		const markToSelect = clusterer
			.getMarkers()
			.findIndex(
				mark =>
					selectedVenue &&
					selectedVenue.location &&
					selectedVenue.location.coordinates[1] === mark.getPosition().lat() &&
					selectedVenue.location.coordinates[0] === mark.getPosition().lng()
			);
		if (markToSelect >= 0) {
			clusterer.getMarkers()[markToSelect].setIcon(selectedMark);
			const pos = clusterer.getMarkers()[markToSelect].getPosition();
			const selectedBounds = new LatLngBounds();
			selectedBounds.extend(new LatLng(pos.lat() - 0.01, pos.lng() - 0.01));
			selectedBounds.extend(new LatLng(pos.lat() + 0.01, pos.lng() + 0.01));
			map.fitBounds(selectedBounds);
			map.panTo(pos);
		} else if (!bounds.isEmpty()) {
			map.fitBounds(bounds);
			map.panToBounds(bounds);
		} else {
			map.setZoom(6);
			map.panTo(new LatLng(51.0968582, 5.9690268));
		}
		return clusterer;
	}

	// 	private static googlePlaceToAddress(place: GooglePlaceDetailsResult): Address {
	// 		const address = new Address();
	// 		for (const component of place.address_components) {
	// 			const addressType = component.types[0];
	// 			if (componentForm[addressType]) {
	// 				const val = component[componentForm[addressType]];
	// 				switch (addressType) {
	// 					case 'street_number':
	// 						address.number = val;
	// 						break;
	// 					case 'route':
	// 						address.street = val;
	// 						break;
	// 					case 'locality':
	// 						address.city = val;
	// 						break;
	// 					case 'administrative_area_level_1':
	// 						address.state = val;
	// 						break;
	// 					case 'country':
	// 						address.country = val;
	// 						break;
	// 					case 'postal_code':
	// 						address.postalCode = val;
	// 						break;
	// 					default:
	// 						break;
	// 				}
	// 			}
	// 		}
	// 		address.lat = place.geometry.location.lat;
	// 		address.lng = place.geometry.location.lng;
	// 		address.placeId = place.place_id;
	// 		return address;
	// 	}
}

export const calculateGeoDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
	const r = 6371; // Radius of the earth in km
	const dLat = deg2rad(lat2 - lat1); // deg2rad below
	const dLon = deg2rad(lon2 - lon1);
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return r * c; // Distance in km
};

export const deg2rad = (deg: number) => {
	return deg * (Math.PI / 180);
};

export const venueToAddress = (venue: Venue) => {
	const address = new Address();
	address.city = venue.city.de;
	address.lat = venue.location.coordinates[1];
	address.lng = venue.location.coordinates[0];
	address.street = venue.street;
	address.number = venue.number;
	address.postalCode = venue.postalCode;
	return address;
};

export const orderToAddress = (order: Order) => {
	const address = new Address();
	address.city = order.preorder.city;
	address.lat = order.preorder.lat;
	address.lng = order.preorder.lng;
	address.street = order.preorder.street;
	address.number = order.preorder.number;
	address.postalCode = order.preorder.postalCode;
	return address;
};
