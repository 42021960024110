import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { getPrice, getSlotsByOrder } from '../../../smoothr-web-app-core/utils/utils';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent, ModalController, PickerController } from '@ionic/angular';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import validator from 'validator';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { Router } from '@angular/router';
import { calculateGeoDistance, MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { sleep, isVenueOpen, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { environment } from 'src/environments/environment';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	isVenueOpen = false;
	tip: number = 0;

	orderForm: FormGroup;
	name = 'name';
	email = 'email';
	phone = 'phone';
	note = 'note';
	orderAt = 'orderAt';

	numberToCurrency = numberToCurrency;
	slots: Moment[] = [];
	environment = environment;

	@ViewChild(IonContent, { static: false }) content;
	@ViewChild('swiperCheckout', { static: false }) slides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	isOverviewPage = false;
	@Output() closedDialog = new EventEmitter<void>();
	street = 'street';
	city = 'city';
	postalCode = 'postalCode';

	number = 'number';
	totalPriceValue: string = '';
	mappedOrderArticlesValue: ArticleGroup[] = [];
	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}
	// ngAfterViewInit(): void {
	// 	this.toOverview(); //TODO: to remove;
	// }

	static async show(modalCtrl: ModalController, analytics: AnalyticsService, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip,
			},
			showBackdrop: true,
			backdropDismiss: true,
			cssClass: 'item-modal-checkout',
		});
		await modal.present();
		analytics.beginCheckout(order);
		return await modal.onDidDismiss();
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.createOrderForm();

		this.checkPrevOrder();
		this.loadSlots();
		this.totalPrice();
		this.mappedOrderArticlesValue = this.order.orderedArticles.map(it => {
			it = this.mapGroup(it);
			return it;
		});
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		this.cdr.detectChanges();
		this.totalPrice();
	}

	checkPrevOrder() {
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				this.orderForm.patchValue({
					name: this.repository._previousPreorder?.name ?? '',
					email: this.repository._previousPreorder?.email ?? '',
					phone: {
						country: '+49',
						phone: this.repository._previousPreorder?.phone?.trim().replace('+49', ''),
					},
				});
			}
		}
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode = this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	checkCustomer() {
		console.log(this.customer);
		if (this.customer) {
			if (this.customer?.name) {
				console.log('HERE', this.customer.name.split(' '));
				this.orderForm.patchValue({
					name: this.customer.name.split(' ')[0],
					surname: this.customer?.name.replace(this.customer.name.split(' ')[0], ''),
				});
			}
			if (this.customer?.email) {
				this.orderForm.patchValue({
					email: this.customer?.email,
				});
			}
			if (this.customer?.phone) {
				this.orderForm.patchValue({
					phone: {
						internationalNumber: this.customer?.phone ?? '',
						dialoCode: '+49',
						isoCode: 'de',
						nationalNumber: this.customer?.phone.trim().replace('+49', ''),
					},
				});
			}
		}
	}

	async openTimeSheet() {
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			let slots = await getSlotsByOrder(this.venue, this.order);
			if (slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}
			if (this.isVenueOpen && (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order))) {
				slots = [null, ...slots];
			}
			const options = {
				columns: [
					{
						name: 'slot',
						class: 'time-picker',
						options: slots.map(slot => {
							return {
								text: this.slotToText(slot ? moment(slot.time) : null),
								value: slot,
							};
						}),
					},
				],
				buttons: [
					{
						text: this.translate.instant('checkout_modal.cancel'),
						role: 'cancel',
					},
					{
						text: this.translate.instant('checkout_modal.confirm'),
						handler: value => {
							const slot = value.slot.value;
							if (!slot) {
								this.order.asap = true;
								this.order.orderAt = null;
								return;
							}
							const conflictingArticles = OrderUtils.slotConflictingArticlesInOrder(moment(slot.time), this.order);
							if (conflictingArticles.length > 0) {
								this.snackbarCtrl.open(
									this.translate.instant('checkout_modal.products_unavailable') +
										conflictingArticles
											.map(conflictingArticle => conflictingArticle.article.name.de)
											.reduce((previousValue, currentValue) => previousValue + ' ' + currentValue),
									null,
									{
										duration: 3000,
									}
								);
								return;
							}
							this.order.orderAt = moment(slot.time).local();
						},
					},
				],
			};
			const picker = await this.pickerCtrl.create(options);

			await picker.present();
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.loading = false;
	}

	async close() {
		await this.updateForm();

		const data = {
			preorder: this.order.preorder,
			orderAt: this.getFormControlValue('orderAt'),
			tip: this.tip,
			asap: this.order.asap,
		};
		this.repository.previousPreorder.emit(this.order.preorder);

		await this.modalCtrl.dismiss(data);
	}

	async updateForm() {
		this.order.preorder.name = this.getFormControlValue('name');
		if (this.getFormControlValue('phone')?.phone && this.getFormControlValue('phone')?.country)
			this.order.preorder.phone = this.getFormControlValue('phone')?.country + this.getFormControlValue('phone')?.phone;
		this.order.preorder.email = this.getFormControlValue('email');
		this.order.preorder.note = this.getFormControlValue('note');
		if (this.getFormControlValue('orderAt')) {
			this.order.orderAt = this.getFormControlValue('orderAt');
		} else {
			this.order.asap = true;
		}
		if (this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.getFormControlValue('city');
			this.order.preorder.postalCode = this.getFormControlValue('postalCode');
			this.order.preorder.number = this.getFormControlValue('number');
			this.order.preorder.street = this.getFormControlValue('street');
		}
		this.repository.onOrderChange(this.order);
	}
	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		const asapPossible = (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) && this.isVenueOpen;
		if (!slot) {
			return this.translate.instant(asapPossible ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot');
		}
		const slotMoment = moment(slot);
		const sameDay = TimeUtils.isSameDate(slotMoment, moment());
		if (sameDay) {
			return slotMoment.format('HH:mm');
		} else {
			return slotMoment.format('DD.MM. HH:mm');
		}
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	async submitFormCustomValidator() {
		let controlStreetValue = this.getFormControlValue('street').trim();
		let controlHouseNumberValue = this.getFormControlValue('number').trim();
		let controlPostalCodeValue = this.getFormControlValue('postalCode').trim();
		let controlCityValue = this.getFormControlValue('city').trim();
		if (
			OrderUtils.isDelivery(this.order) &&
			(controlStreetValue !== this.address.street ||
				controlPostalCodeValue !== this.address.postalCode ||
				controlCityValue !== this.address.city ||
				controlHouseNumberValue !== this.address.number)
		) {
			try {
				const pred = controlStreetValue + ' ' + controlHouseNumberValue + ', ' + controlCityValue + ' ' + controlPostalCodeValue;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					// this.streetError = this.translate.instant(addressValidationResult);
					this.orderForm.controls['street'].setErrors({ incorrect: true });
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.orderForm.controls['street'].setErrors({ street_delivery: true });

					this.snackbarCtrl.open(this.translate.instant('errors.street_delivery'), null, {
						duration: 2000,
					});
					return false;
				}
				this.repository.address.emit(address);
				this.orderForm.patchValue({
					street: this.address.street,
				});
				return true;
			} catch (e) {
				this.orderForm.controls['street'].setErrors({ street_not_found: true });
				this.snackbarCtrl.open(this.translate.instant('errors.street_not_found'), null, {
					duration: 2000,
				});
				return false;
			}
		} else {
			return true;
		}
	}
	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}
	reloadAddress() {
		if (this.order && this.order.preorder && this.address && this.order.preorder.type === PreorderType.DELIVERY && this.orderForm) {
			this.orderForm.patchValue({
				city: this.address?.city ?? '',
				street: this.address?.street ?? '',
				postalCode: this.address?.postalCode && this.address?.postalCode?.length > 0 ? this.address.postalCode : '',
				country: this.address?.country,
				number: this.address?.number,
			});
		}
	}
	async onButtonClick() {
		if (this.order?.preorder?.type === PreorderType.DELIVERY) {
			if (!(await this.submitFormCustomValidator())) {
				return;
			}
		}

		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}

	async toOverview() {
		// await this.slides.update();
		await this.slides.nativeElement.swiper.slideNext();
		console.log('Slide next');
		console.dir(this.slides.nativeElement);
		this.isOverviewPage = true;
	}
	async editPersonalData() {
		await this.slides.nativeElement.swiper.slidePrev();
		this.isOverviewPage = false;
	}
	slotSelected($event) {
		this.order.orderAt = $event;
		if (!$event) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
	}
	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}
	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		console.log('LOAD SLOTS', this.venue, this.order);
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		// if (this.loading) {
		// 	return;
		// }
		this.loading = true;
		try {
			//TODO: not touch while TUR testing

			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot => moment(slot.time));
			console.log('THIS sLots', this.slots);
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}
			// this.slots = [null, ...this.slots];
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.loading = false;
	}
	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		if (articleGroup) {
			const findGroup = articleGroup.article.groups.find(it => it._id === articleOption.group);
			if (findGroup) {
				if (findGroup.tags.find(it => it.identifier === 'half_pizza')) {
					return 0;
				}
			}
		}
		return price * articleOption.quantity;
	}
	createOrderForm() {
		const formControlNames: any = {};

		formControlNames[this.name] = ['', [Validators.required, Validators.pattern(/\w+\s\w+/)]];

		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				),
			],
		];

		formControlNames[this.phone] = ['', [Validators.required, this._customPhoneValidatorForLibrary]];

		formControlNames[this.note] = ['', []];

		formControlNames[this.orderAt] = [''];
		if (this.order.preorder.type === PreorderType.DELIVERY) {
			formControlNames[this.street] = ['', [Validators.required]];
			formControlNames[this.city] = ['', [Validators.required]];
			formControlNames[this.postalCode] = ['', [Validators.required]];
			formControlNames[this.number] = ['', [Validators.required]];
		}
		this.orderForm = this.fb.group(formControlNames);
		this.checkCustomer();
		this.reloadAddress();
	}

	private _customPhoneValidatorForLibrary(control: AbstractControl) {
		if (!control.value?.phone) {
			return null;
		}
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value?.country + control?.value?.phone.toString());
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}

	getFormControlValue(controlName?: string) {
		if (controlName) {
			return this.orderForm.get(controlName)?.value;
		}
		return '';
	}
	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm?.get(controlName)?.hasError('required') && this.orderForm?.get(controlName)?.touched;
		}
		return this.orderForm?.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm?.get(controlName)?.errors && !this.orderForm?.get(controlName)?.hasError('required');
		}
		return this.orderForm.invalid;
	}

	getErrorsForm(controlName) {
		if (controlName) {
			return this.orderForm.get(controlName).errors;
		}

		return null;
	}

	private _customPhoneValidator(control: AbstractControl) {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}

	handleChangeRadio(event: { detail: { value: string } }) {
		console.log(event.detail.value);
	}
	totalPrice() {
		console.log('BEFORE MAP', this.order, this.venue);
		if (this.venue) {
			const foundArticleIndex = this.order.orderedArticles.findIndex(it => it.isHalfPizza);
			if (foundArticleIndex >= 0) {
				console.log('here before calc');
				const foundArticle = this.venue.articleCategories
					.map(it => it.articles.find(ar => ar.tags.find(tag => tag.identifier == 'appliesMostExpensive')))
					.filter(it => !!it);
				console.log(foundArticle);
				if (foundArticle.length > 0) {
					let mappedOrder = JSON.parse(JSON.stringify(this.order));
					mappedOrder.orderedArticles = mappedOrder.orderedArticles.map(it => {
						if (it.isHalfPizza) {
							it.article = foundArticle[0];
						}
						return it;
					});
					// mappedOrder.orderedArticles[foundArticleIndex].article = foundArticle[0];
					this.totalPriceValue = numberToCurrency(
						OrderUtils.orderTotalPrice(mappedOrder, true, true) + this.tip,
						this.order.currency
					);
					return;
				}
			}
			this.totalPriceValue = numberToCurrency(OrderUtils.orderTotalPrice(this.order, true, true) + this.tip, this.order.currency);
		} else {
			this.totalPriceValue = numberToCurrency(OrderUtils.orderTotalPrice(this.order, true, true) + this.tip, this.order.currency);
		}
	}
	changeTip(tip: number) {
		this.tip = tip;
		this.totalPrice();
	}

	checkPriceArticle(articleGroup: ArticleGroup, orderType: OrderType, preorderType: PreorderType) {}
	mapGroup(articleGroup: ArticleGroup) {
		console.log('MapGroup');
		if (!this.venue || !this.order) {
			return articleGroup;
		}
		if (this.venue) {
			const foundArticle = this.venue.articleCategories
				.map(it => {
					if (it.visible) {
						const foundArticle = it.articles.find(ar => ar._id === articleGroup.article._id);

						return foundArticle ?? null;
					} else {
						return null;
					}
				})
				.filter(it => !!it);
			if (foundArticle.length === 1) {
				const test = JSON.parse(JSON.stringify(articleGroup)) as ArticleGroup;
				test.article = JSON.parse(JSON.stringify(foundArticle[0]));

				return test;
			}
			return articleGroup;
		}
		return articleGroup;
	}
}
