import { Component } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-banner-info',
	templateUrl: './banner-info.component.html',
	styleUrls: ['banner-info.component.scss'],
})
export class BannerInfoComponent {
	constructor(public modalCtrl: ModalController) {}
	static async show(modalCtrl: any): Promise<null | { isNext: boolean }> {
		const modal = await modalCtrl.create({
			component: BannerInfoComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'banner-modal',
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	goNext() {
		this.modalCtrl.dismiss({ isNext: true });
	}
}
