import { Component } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { SignInPage } from '../../pages/sign-in/sign-in.page';
import { Router } from '@angular/router';
import { SignUpPage } from '../../pages/sign-up/sign-up.page';
import { PopoverController, ModalController } from '@ionic/angular';
import { HomePage } from '../../pages/home/home.page';
import { MyOrdersPage } from '../../pages/my-orders/my-orders.page';
import { AccountPage } from '../../pages/account/account.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import { TosPage } from '../../pages/tos/tos.page';
import { ImpressumPage } from '../../pages/impressum/impressum.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AuthStrategy } from 'src/smoothr-web-app-core/models/AuthStrategy';
enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['menu-modal.component.scss'],
})
export class MenuModalComponent extends RepositoryDirective {
	environment = environment;
	languageEnum = Language;
	loadingHelloAgain = false;
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService,
		private navigationService: NavigationService
	) {
		super(repository);
		this.repository.customerAuth.subscribe();
	}
	ngOnInit(): void {
		super.ngOnInit();
		this.repository.reloadCustomer();
	}
	onCustomer(): void {
		console.log(this.customer);
	}
	async signIn() {
		try {
			await this.modalController.dismiss();
			// await this.modalController.dismiss();
			// await this.navigationService.signIn();
			this.helloAgain();
		} catch (e) {
			// await this.navigationService.signIn();
		}
	}
	async helloAgain() {
		if (!this.loadingHelloAgain) {
			this.loadingHelloAgain = true;
			try {
				const result = (await Api.loginHelloAgain(AuthStrategy.HELLOAGAIN)).data;
				console.log(result);
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loadingHelloAgain = false;
		}
	}
	async signUp() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.signUp();
		} catch (e) {
			await this.navigationService.signUp();
		}
	}

	async mySpace() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.account();
		} catch (e) {
			await this.navigationService.account();
		}
	}

	async myOrders() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();

			await this.navigationService.myOrders();
		} catch (e) {
			await this.navigationService.myOrders();
		}
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_success'), null, {
				duration: 2000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_error'), null, {
				duration: 2000,
			});
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await this.navigationService.home();
	}

	async privacy() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.privacy();
		} catch (e) {
			await this.navigationService.privacy();
		}
	}

	async tos() {
		await this.modalController.dismiss();
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.tos();
		} catch (e) {
			await this.navigationService.tos();
		}
	}

	async impressum() {
		try {
			await this.modalController.dismiss();
			await this.modalController.dismiss();
			await this.navigationService.imprint();
		} catch (e) {
			await this.navigationService.imprint();
		}
	}
	async allergens() {
		await this.modalController.dismiss();
		// TODO open PDF
	}

	dismiss() {
		this.modalController.dismiss();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
