import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AnalyticsService } from '../smoothr-web-app-core/services/analytics/analytics.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Api } from '../smoothr-web-app-core/api/api';

// import { SwUpdate } from '@angular/service-worker';
import { NavigationService } from './services/navigation.service';
import { filter } from 'rxjs/operators';

import { register } from 'swiper/element/bundle';
import { CookieConsentService } from './services/cookie.service';
register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;
	isCookieAccepted = false;

	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AnalyticsService,
		platform: Platform,
		private translate: TranslateService,
		private cookieService: CookieConsentService,

		private navigationService: NavigationService,
		private modalCtrl: ModalController
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/losteria/logo.svg';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant('index.title.' + environment.customerGroup);
		translate.setDefaultLang('de');
		translate.use('de');
		this.handleCookieConsent();
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					this.navigationService.maintenance();
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	ngOnInit() {}

	ngDoCheck() {}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	private async initCookieConsent() {}
	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
	private handleCookieConsent() {
		try {
			this.cookieService.initializeCookieConsent();
		} catch (e) {}
	}
}
