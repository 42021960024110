import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import { getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { getAvailability } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-checkbox',
	templateUrl: './article-option-group-checkbox.component.html',
	styleUrls: ['article-option-group-checkbox.component.scss'],
})
export class ArticleOptionGroupCheckboxComponent implements OnInit {
	optionGroup: OptionGroup = null;
	@Input() set optionGroupValue(value: OptionGroup) {
		if (value) {
			if (value.articles.length > 0) {
				value.articles = value.articles.filter(it => !this.isHidden(it) && !it.hidden);
				this.optionGroup = value;
			}
		}
	}
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	@Output() openInfo = new EventEmitter<Article>();
	showFirstArticles = true;

	ot = OrderType;
	di = DisplayIdentifier;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	numberD = numberD;
	basePrice = '';

	constructor() {}
	ngOnInit(): void {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	onSelectionChange(article: Article, quantity: number) {
		console.log(this.optionGroup);
		console.log('on selection change', article.name.de, quantity);
		if (this.isDisabled(article)) {
			return;
		}
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = quantity;
		this.selection.emit(articleOption);
	}

	toggle(option: Article) {
		this.onSelectionChange(option, this.selected.map(ao => ao.article?._id).indexOf(option?._id) < 0 ? 1 : -1);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	isOptionSelected(option: Article): boolean {
		return this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined;
	}
}
