import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AuthStrategy } from 'src/smoothr-web-app-core/models/AuthStrategy';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss'],
})
export class MapPage {
	loadingHelloAgain = false;
	constructor(
		private navService: NavigationService,
		private router: Router
	) {}
	static url = 'map';

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}
	goHome() {
		this.navService.home();
	}
	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
	openUrl() {
		// window.location.assign('');
		const deviceType = this.getDeviceType();
		if (deviceType === 'Android' || deviceType === 'iOS') {
			window.location.assign('https://bit.ly/4b5EDGG');
		} else {
			window.location.assign('https://losteria.net/de/aktuelles/amici-club/');
		}
	}
	getDeviceType(): string {
		const userAgent = navigator.userAgent || navigator.vendor;

		// Check if the device is Android
		if (/android/i.test(userAgent)) {
			return 'Android';
		}

		// Check if the device is iOS
		if (/iPhone|iPad|iPod/i.test(userAgent)) {
			return 'iOS';
		}

		// If it is neither Android nor iOS, assume it is Desktop
		return 'Desktop';
	}
	async helloAgain() {
		if (!this.loadingHelloAgain) {
			this.loadingHelloAgain = true;
			try {
				const result = (await Api.loginHelloAgain(AuthStrategy.HELLOAGAIN)).data;
				console.log(result);
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loadingHelloAgain = false;
		}
	}
}
