import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { environment } from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-item-selectable-article-custom',
	templateUrl: './item-selectable-article-custom.component.html',
	styleUrls: ['item-selectable-article-custom.component.scss'],
})
export class ItemSelectableArticleCustomComponent implements OnInit {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Input() currency: string;
	numberToCurrency = numberToCurrency;
	basePrice = '';

	constructor(private modalCtrl: ModalController) {}
	ngOnInit(): void {
		this.showMeasurement(this.article);
	}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}
	addItem() {
		if (this.selected && (this.article?.tags ?? []).find(it => it?.identifier === 'has_deposit')) {
			return;
		}
		if (this.isEmptyArticle) {
			this.add.emit();
			return;
		}
		if (this.group && !this.group.hasMultiple) {
			this.add.emit();
			return;
		}
		if (this.group && this.group.hasMultiple && this.selected == 0) {
			this.add.emit();
			return;
		}
		// this.isEmptyArticle || ? this.add.emit() : null
	}

	async openInfoClick(option: Article) {
		await AllergensInfoModalComponent.show(this.modalCtrl, option);
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article.nutritions)
			.map(it => (it ? numberD(it) : -1))
			.filter(it => typeof it === 'number')
			.some(it => +it >= 0);
	}

	showMeasurement(article: Article) {
		// console.log('!!!!!!!!', article, article?.measurement?.refAmount, article?.measurement?.refUnit, article?.measurement?.amount);
		if (article && this.preorderType && (article.basePriceByType || (article.basePrice && +article.basePrice.$numberDecimal > 0))) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit && article.measurement.amount) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					this.basePrice =
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency);
					return;
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					this.basePrice =
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType) * 10, this.currency);
					return;
				}
				this.basePrice =
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency);
				return;
			}
		} else {
			return (this.basePrice = '');
		}
	}
}
