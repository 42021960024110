import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import { filterMatchingOptions } from 'src/smoothr-web-app-core/utils/order-utils';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-show-remove-ingrediants-dialog',
	templateUrl: './show-remove-ingrediants-dialog.component.html',
	styleUrls: ['show-remove-ingrediants-dialog.component.scss'],
})
export class ShowRemoveIngrediantsDialogComponent implements OnInit {
	public optionGroup: OptionGroup;
	public order: Order;
	public articleGroup: ArticleGroup;
	public listOfArticleOptions: ArticleOption[] = [];
	filterMatchingOptions = filterMatchingOptions;
	constructor(private modalCtrl: ModalController) {}
	static async show(
		modalCtrl: ModalController,
		optionGroup: OptionGroup,
		articleGroup: ArticleGroup,
		order: Order
	): Promise<{ data: ArticleOption[] }> {
		const modal = await modalCtrl.create({
			cssClass: 'eight_height',
			component: ShowRemoveIngrediantsDialogComponent,
			componentProps: {
				optionGroup,
				order,
				articleGroup,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {}
	addOption(event: ArticleOption) {
		if (this.listOfArticleOptions.find(it => it.article._id === event.article._id)) {
			this.listOfArticleOptions = this.listOfArticleOptions.filter(it => it.article._id !== event.article._id);
		} else {
			this.listOfArticleOptions.push(event);
		}
		console.log(this.listOfArticleOptions);
	}
	select() {
		this.modalCtrl.dismiss({
			data: this.listOfArticleOptions,
		});
	}
	close() {
		this.modalCtrl.dismiss();
	}
}
