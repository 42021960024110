import { Gender } from '../enums/Gender';
import { Moment } from 'moment/moment';

export default class Customer {
	_id?: string;
	name?: string;
	street?: string;
	city?: string;
	postalCode?: string;
	companyName?: string;
	preferredVenues?: string[];
	number?: string;
	gender?: Gender;
	emailVerifiedAt?: Moment;
	phone?: string;
	email?: string;
	country?: string;
	floor?: string;
	subcard?: string;
	loyaltyNumber?: string;
	uid: string;
	isAnonymous: boolean;
	birthDate?: string;
	subscribedToMailing?: boolean;
	registerInPiggy?: boolean;
	piggyIsRegistered: boolean;
	piggyMembershipLvl: string;
	piggyPoints: number;
	piggyPointsTotal: number;
	piggyUuid: string;
	favoriteVenues: string[];
	salesforceEnabled?: string;
	helloAgainLoyaltyPoints?: number;
	helloAgainLoyaltyPointsTotal?: number;
}
